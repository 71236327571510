import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { IconVue } from '../components/icons/IconVue';
import { IconReact } from '../components/icons/IconReact';
import { SEO } from '../components/SEO';
import { LayoutApp } from '../layouts/LayoutApp';
import { DemoContainer } from '../components/DemoContainer';
import { CodeBlock } from '../components/CodeBlock';

const Post = ({ data }) => {
  const post = data.mdx;

  return (
    <LayoutApp>
      <SEO
        title={post.frontmatter.title}
        previewImage={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        previewImageTwitter={
          post.frontmatter.featuredImage.childImageSharp.fluid.src
        }
      />
      <div className="mb-8">
        <h1 className="mb-2 text-3xl font-extrabold leading-9 sm:leading-none sm:text-6xl">
          {post.frontmatter.title}
        </h1>
        <p className="text-gray-700">
          {post.frontmatter.date} — {post.fields.readingTime.text}
        </p>
      </div>
      <article
        id="kitwind-article"
        className="mb-16 leading-relaxed whitespace-pre-line"
      >
        <MDXProvider
          components={{
            IconVue,
            IconReact,
            code: CodeBlock,
            DemoContainer,
            a: ({ children, ...rest }) => (
              <a
                className="font-medium text-indigo-600 transition-colors duration-200 hover:text-indigo-700 hover:underline"
                {...rest}
              >
                {children}
              </a>
            ),
            Link: (props) => (
              <Link
                className="font-medium text-indigo-600 transition-colors duration-200 hover:text-indigo-700 hover:underline"
                {...props}
              />
            ),
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </article>
    </LayoutApp>
  );
};

export default Post;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
